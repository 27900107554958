.Contact {
  height: 100vh;
  position: relative;
}
#root {
  overflow-x: hidden;
  max-width: 100vw;
}
.Contact-bg {
  width: 20px;
  height: 100%;
  background-color: #59b256;
  position: absolute;
}
.Contact-wrapper {
  padding: 50px;
  display: flex;
}
.Contact-Left {
  flex: 1;
}
.Contact-Right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Contact-title {
  font-size: 60px;
  width: 80%;
}
.Contact-info-item {
  display: flex;
  align-items: center;
  margin: 50px 0px;
  font-weight: 300;
  width: 70%;
}
.Contact-icon {
  width: 40px;
  height: 30px;
  margin-right: 20px;
}
form {
  margin-top: 20px;
}
input {
  width: 50%;
  height: 50px;
  border: none;
  border-bottom: 1px solid black;
  margin: 10px 0px;
  font-size: 14px;
  padding-left: 10px;
}
textarea {
  width: 100%;
  margin: 10px 0px;
  font-size: 14px;
  padding-left: 10px;
}
.submit {
  border: none;
  padding: 15px;
  background-color: red;
  color: white;
  font-weight: 500;
  cursor: pointer;
}
.submit:hover {
  background-color: white;
  border: 1px solid red;
  color: red;
}
.iconify {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .Contact-wrapper {
    flex-direction: column;
    padding: 0px 50px;
  }

  .Contact-title {
    font-size: 30px;
  }

  .Contact-info-item {
    margin: 20px 0px;
    display: flex;
    width: 100%;
  }

  .c-desc {
    display: none;
  }

  form {
    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
  }

  input {
    width: 35%;
    height: 40px;
    margin: 10px;
    margin-left: 0;
  }

  button {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .wrap,
  .wrap .Contact-info-item {
    display: flex;
    justify-content: space-around;
    width: 100%;
    flex: 1;
    align-items: center;
  }
}
