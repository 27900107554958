.about {
  height: 100vh;
  display: flex;
  align-items: center;
}
.about-left {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
}
.about-right {
  flex: 1;
}
.about-card {
  width: 60%;
  height: 70vh;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
}
.about-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}
.about-card.bg {
  position: absolute;
  top: 50px;
  left: 50px;
  background-color: #a4133c;
}
@media screen and (max-width: 480px) {
  @media screen and (max-width: 480px) {
    .about {
      flex-direction: column;
      text-align: center;
      margin-top: 50px;
    }

    .about-left {
      width: 100%;
    }

    .about-card {
      height: 30vh;
    }

    .about-card.bg {
      display: none;
    }

    .about-right {
      padding: 20px;
    }
  }
}
