body {
  overflow-x: hidden;
}
.intro {
  display: flex;
  height: 100vh;
  min-width: 100vw;
  background-color: #f8f8f8;
}
.i-desc {
  width: 80%;
}
.intro-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.intro-right {
  flex: 1;
  position: relative;
}
.intro-left-wrapper {
  padding: 50px;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.intro-intro {
  font-size: 30px;
  font-weight: 300;
}
.intro-name {
  font-size: 60px;
}
.intro-title {
  height: 50px;
  overflow: hidden;
}
.intro-title-wrapper {
  height: 100%;
  animation: move 10s ease-in-out infinite alternate;
}
@keyframes move {
  25% {
    transform: translateY(-50px);
  }
  50% {
    transform: translateY(-100px);
  }
  75% {
    transform: translateY(-150px);
  }
}
.intro-title-item {
  height: 50px;
  font-size: 30px;
  font-weight: bold;
  color: red;
  display: flex;
  align-items: center;
}
.i-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 3 !important;
  position: absolute;
  object-position: center;
}
.i-background {
  clip-path: polygon(100% 0%, 75% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
  background-color: #005f73;
  width: 119%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
@media screen and (max-width: 480px) {
  .intro {
    flex-direction: column;
    justify-content: space-between;
  }
  .intro-left-wrapper {
    padding: 10px;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
  }
  .intro-title-wrapper > * {
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 320px;
  }
  .intro-name {
    font-size: 30px;
  }
  .intro-title-item {
    text-align: center;
  }
  .i-img,
  .i-background {
    display: none;
  }
  .intro {
    height: fit-content;
  }
}
